import { loggerWithPrefix } from "@/lib/logger";
import { getPostHogApiKey, getPostHogMode } from "@/lib/posthog";
import { Router } from "next/router";
import posthog, { PostHog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";

const logger = loggerWithPrefix("PostHogWrapper");
const posthogMode = typeof window === "undefined" ? "disabled" : getPostHogMode();
const apiKey = getPostHogApiKey(posthogMode);

const defaultPosthogConfig = {
  api_host: "/posthog/",
  ui_host: "https://us.posthog.com",
  opt_in_site_apps: true,
  person_profiles: "identified_only",
  disable_session_recording: false,
} as const;

// Set up posthog per this guide:
// https://posthog.com/docs/libraries/next-js?tab=Pages+router
export default function PostHogWrapper({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    if (!apiKey) return;
    const config = {
      ...defaultPosthogConfig,
      loaded: (posthog: PostHog) => {
        const GIT_HASH = process.env.GIT_HASH;
        if (GIT_HASH) {
          posthog.register({
            deployment_id: GIT_HASH,
          });
        }
        posthog.debug(posthogMode === "debug");
      },
    };

    logger.debug("Initializing PostHog", config);
    posthog.init(apiKey, config);

    const handleRouteChange = () => posthog?.capture("$pageview");

    Router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return apiKey ? <PostHogProvider client={posthog}>{children}</PostHogProvider> : <>{children}</>;
}
