Object.defineProperty(Error.prototype, "toJSON", {
  value: function () {
    const self = this as Error;
    // This allows for errors to be formatted nicely in JSON from the logger
    // or JSON.stringify(error).
    return {
      name: self.constructor?.name || self.name,
      message: self.message?.substring(0, 500),
      cause: self.cause,
      stack: self.stack
        ?.replace(
          // remove redundant first line in stacktrace
          new RegExp(
            String.raw`^${self.name}: ${self.message?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}\n\s*at\s*`,
          ),
          "",
        )
        .split(/\n\s*/)
        .map((line: string) => line.replace(/^at /, "")),
    };
  },
  configurable: true,
  writable: true,
});

export class CrawlError extends Error {}
export class CookieError extends CrawlError {}
export class FallbackToDevCookiesError extends CrawlError {}
export class LinkedinCrawlError extends CrawlError {}
export class RetriesExhaustedCrawlError extends CrawlError {}
export class FallbackCrawlError extends CrawlError {}
export class LambdaCrawlError extends CrawlError {}
export class LambdaRetriesExhaustedCrawlError extends LambdaCrawlError {}

export class UserVisibleError extends Error {}
