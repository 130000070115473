import "@/lib/error";
import { loggerWithPrefix } from "@/lib/logger";
import HyperDXBrowser from "@hyperdx/browser";
import { Attributes } from "@opentelemetry/api";

const logger = loggerWithPrefix("[ET]");

// error tracker
class ErrorTracker {
  hyperDX: {
    addAction: (action: string, metadata: Attributes) => void;
    recordException: (error: unknown, metadata?: Attributes) => void;
    setGlobalAttributes: (attributes: Record<string, string>) => void;
  } = HyperDXBrowser;

  constructor() {
    if (process.env.CI) return;
    const isProd = process.env.NODE_ENV === "production";
    if (typeof window === "undefined") {
      void import("@hyperdx/node-opentelemetry").then((HyperDXNode) => {
        this.hyperDX = {
          addAction: (action, metadata) => logger.info("[hyperdx] addAction", action, metadata),
          recordException: (error, metadata) => {
            void HyperDXNode.recordException(error, metadata);
          },
          setGlobalAttributes: (attributes) => {
            HyperDXNode.setTraceAttributes(attributes);
          },
        };
      });
    } else {
      // hyperdx browser only works on the frontend (it checks for window before sending any errors)
      HyperDXBrowser.init({
        apiKey: (isProd && process.env.HYPERDX_API_KEY) || "",
        service: `frontend-${process.env.NODE_ENV}`,
        // this levereges route rewrites in next.config.js. Remove to connect
        // to HyperDX directly.
        url: "/hyperdx",
        tracePropagationTargets: [/distill.fyi/i],
        advancedNetworkCapture: true,
        instrumentations: {
          // suppress auto-instrumentation of errors as it's quite noisy
          errors: false,
        },
      });
      HyperDXBrowser.setGlobalAttributes({
        gitHash: process.env.GIT_HASH || "",
      });

      window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
        this.sendError(event.reason, {
          unhandledPromiseRejection: true,
        });
      });

      window.addEventListener("error", (event: ErrorEvent) => {
        this.sendError(event.error, {
          windowError: true,
        });
      });
    }
  }

  testConfig() {
    logger.info("hyperdx", process.env.HYPERDX_API_KEY, this.hyperDX);
  }

  /** this only works on the frontend */
  addBreadcrumb(breadcrumb: {
    category: string;
    action: string;
    message?: string;
    metadata?: Attributes;
  }) {
    logger.debug(breadcrumb);
    this.hyperDX.addAction(breadcrumb.action, {
      category: breadcrumb.category,
      message: breadcrumb.message,
      ...breadcrumb.metadata,
    });
  }

  sendError(error: unknown, metadata?: Attributes) {
    logger.error(error, metadata);

    if (process.env.NODE_ENV != "production") return;

    this.hyperDX.recordException(error, metadata);
  }

  /** this only works on the frontend */
  setUser(user: { id: string; email?: string | null; name?: string | null }) {
    this.hyperDX.setGlobalAttributes({
      userId: user.id,
      userEmail: user.email || "",
      userName: user.name || "",
    });
  }
}

const errorTracker = new ErrorTracker();
export default errorTracker;
