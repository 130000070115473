export const PRODUCT = "Distill";

export type ValueOf<T> = T[keyof T];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Impossible<K extends keyof any> = {
  [P in K]: never;
};

export type NoExtraProperties<T, U extends T = T> =
  U extends Array<infer V> ? NoExtraProperties<V>[] : U & Impossible<Exclude<keyof U, keyof T>>;

export * from "./ai";
export * from "./analytics";
export * from "./api";
export * from "./attributes";
export * from "./crawlerType";
export * from "./db";
export * from "./deprecated";
export * from "./emails";
export * from "./enums";
export * from "./google";
export * from "./lists";
export * from "./pipeline";
export * from "./scraping";
export * from "./snapshots";
export * from "./socialAccount";
export * from "./testing";
export * from "./ui";
