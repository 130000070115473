declare global {
  interface Window {
    stores: unknown;
  }
}

export const exportStores = async () => {
  // we async import to prevent circular dependencies
  const API = await import("@/client/api");
  const { dashboardStore } = await import("@/stores/dashboardStore");
  const { entityStore } = await import("@/stores/entityStore");
  const { meetingStore } = await import("@/stores/meetingStore");
  const { onboardingStore } = await import("@/stores/onboardingStore");
  const { searchStore } = await import("@/stores/searchStore");
  const { settingsStore } = await import("@/stores/settingsStore");
  const { uiStore } = await import("@/stores/uiStore");
  const { feedbackStore } = await import("@/stores/feedbackStore");
  const { gmailSidebarStore } = await import("@/stores/gmailSidebarStore");
  const { feedbackListingStore } = await import("@/stores/feedbackListingStore");
  const { listStore } = await import("@/stores/listStore");

  const stores = {
    API: API,
    dashboard: dashboardStore,
    entity: entityStore,
    meeting: meetingStore,
    onboarding: onboardingStore,
    feedback: feedbackStore,
    feedbackListing: feedbackListingStore,
    search: searchStore,
    settings: settingsStore,
    ui: uiStore,
    gmailSidebar: gmailSidebarStore,
    lists: listStore,
  };

  if (typeof window !== "undefined") {
    window.stores = stores;
  }
};
