import "@/lib/errorTracker";
import "@/styles/globals.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";

import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/ibm-plex-sans/700.css";

// While nextJS provides some polyfills, it does not do it for features
// introduced past a certain cut-off. This is why we need to import them.
import "core-js/features/array/to-reversed";
import "core-js/features/array/to-sorted";
import "core-js/features/array/to-spliced";

import { SessionProvider } from "next-auth/react";

import ErrorBoundary from "@/components/ui/ErrorBoundary";

import PostHogWrapper from "@/components/analytics/PostHogWrapper";
import RouteTransition from "@/components/ui/RouteTransition";
import type { Session } from "next-auth";
import type { AppProps } from "next/app";

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  return (
    <PostHogWrapper>
      <ErrorBoundary>
        <RouteTransition>
          <SessionProvider session={session}>
            <Component {...pageProps} />
          </SessionProvider>
        </RouteTransition>
      </ErrorBoundary>
    </PostHogWrapper>
  );
}

if (process.env.NODE_ENV === "development") {
  // debugging shortcut
  global.window?.window.addEventListener("keydown", function (event) {
    if (event.key == "F6") {
      debugger;
    }
  });
}
