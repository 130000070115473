import { logger } from "@/lib/logger";
import { Static, Type } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";

const PostHogMode = Type.Union([
  Type.Literal("enabled"),
  Type.Literal("disabled"),
  Type.Literal("debug"),
]);
export type PostHogMode = Static<typeof PostHogMode>;
export function isPostHogMode(u: unknown): u is PostHogMode {
  return Value.Check(PostHogMode, u);
}

export function getPostHogMode(): PostHogMode {
  const NEXT_PUBLIC_POSTHOG_MODE = process.env.NEXT_PUBLIC_POSTHOG_MODE;
  if (!NEXT_PUBLIC_POSTHOG_MODE) {
    if (process.env.CI) return "disabled";
    if (process.env.NODE_ENV === "development") return "debug";
    return process.env.NODE_ENV === "production" ? "enabled" : "disabled";
  }
  if (!isPostHogMode(NEXT_PUBLIC_POSTHOG_MODE)) {
    logger.error(`Invalid NEXT_PUBLIC_POSTHOG_MODE: ${NEXT_PUBLIC_POSTHOG_MODE}`);
    return "disabled";
  }
  return NEXT_PUBLIC_POSTHOG_MODE;
}

export function getPostHogApiKey(posthogMode: PostHogMode): string | undefined {
  if (posthogMode === "disabled") {
    return undefined;
  }
  const NEXT_PUBLIC_POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
  if (!NEXT_PUBLIC_POSTHOG_KEY) {
    logger.error("NEXT_PUBLIC_POSTHOG_KEY is not set, PostHog is disabled");
  }
  return NEXT_PUBLIC_POSTHOG_KEY;
}
