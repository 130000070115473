import { ComponentType, PropsWithChildren, ReactElement } from "react";

import errorTracker from "@/lib/errorTracker";
import HyperDX from "@hyperdx/browser";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

export default function ErrorBoundary({
  fallback,
  tags,
  children,
  fixture,
}: PropsWithChildren<{
  fallback?: ReactElement;
  tags?: Record<string, string>;
  fixture?: boolean;
}>) {
  if (process.env.NODE_ENV === "development" && !fixture) {
    return <>{children}</>;
  }

  const onError = (error: Error, info: { componentStack?: string | null }) => {
    if (!fixture)
      errorTracker.sendError(error, { ...tags, componentStack: info.componentStack ?? undefined });
  };

  return (
    <ReactErrorBoundary fallback={fallback || <div />} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
}

export function withErrorBoundary<T>(
  WrappedComponent: ComponentType<T>,
  fallback?: ReactElement,
  fixture?: boolean,
) {
  return function WithErrorBoundary(props: React.JSX.IntrinsicAttributes & T) {
    return (
      <ErrorBoundary fallback={fallback} fixture={fixture}>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
}
