import API from "@/client/api";
import { CreateListData, UpdateListData } from "@/client/lists";
import errorTracker from "@/lib/errorTracker";
import { loggerWithPrefix } from "@/lib/logger";
import { prettyError } from "@/lib/utils";
import { ListEntrySnapshotsResult, ListEntryUpdate, UpdateListViewParams } from "@/lists";
import { uiStore } from "@/stores/uiStore";
import {
  ListCustomFieldValue,
  ListDetails,
  ListEntryCustomFieldSpec,
  ListEntryDetails,
  ListEntrySnapshot,
  ListEntrySpec,
  ListMaxSizeReachedError,
  ListOverview,
  ListRoutes,
  ListView,
  PagingOptions,
} from "@/types";
import { atom, computed, map, onMount } from "nanostores";
import { toast } from "react-toastify";

const logger = loggerWithPrefix("[lists-store]");

type UpdateViewParams = Omit<UpdateListViewParams["data"], "listId" | "viewId">;
type Channel = {
  unsubscribe: () => void;
};

type EntityProgressUpdate = {
  label: string;
  stepsLeft: number;
  estimatedSecs: number;
};

export class ListStore {
  readonly list = atom<ListDetails | null>(null);
  readonly snapshots = atom<ListEntrySnapshot[] | null>(null);
  readonly totalSnapshots = atom(0);
  readonly view = atom<ListView | null>(null);
  readonly entries = atom<ListEntryDetails[] | null>(null);

  readonly page = atom(0);
  readonly pageSize = atom(50);
  readonly totalEntries = atom(0);

  // This is set by a feature flag in useUIStore
  readonly maxSize = atom<number>(100);
  readonly maxSizeReached = computed([this.maxSize, this.totalEntries], (maxSize, totalEntries) => {
    return totalEntries >= maxSize;
  });

  readonly listOverviews = atom<ListOverview[] | null>(null);
  readonly totalListOverviews = atom(0);

  private readonly subscriptions: Record<string, Channel> = {};
  readonly entitiesInProgress = map<Record<string, EntityProgressUpdate | undefined>>({});

  readonly entriesWithQueuedEntities = computed(this.entries, (entries) => {
    if (!entries) return [];
    return entries.filter((entry) => {
      const { entity } = entry;
      if (!entity) return false;
      return !entity.generatedAt;
    });
  });

  onListChange = (listDetails: ListDetails | null) => {
    if (listDetails) {
      if (listDetails.views.length > 0) {
        this.view.set(listDetails.views[0]);
      } else {
        this.view.set(null);
      }
      void this.loadEntries(listDetails.id, { page: 0, pageSize: this.pageSize.get() });
    } else {
      this.entries.set(null);
      this.view.set(null);
    }
    void this.loadOverviews();
  };

  onViewChange = (view: ListView | null) => {
    this.snapshots.set(null);
  };

  onEntriesChange = (entries: readonly ListEntryDetails[] | null) => {
    if (!entries) {
      this.totalEntries.set(0);
    }
  };

  onSnapshotsChange = (snapshots: readonly ListEntrySnapshot[] | null) => {
    if (!snapshots) {
      this.totalSnapshots.set(0);
    }
  };

  onEntriesWithQueuedEntitiesChange = (entries: readonly ListEntryDetails[] | null) => {
    if (!entries) {
      return;
    }
    entries.forEach((entry) => {
      if (entry.entityId && !this.subscriptions[entry.entityId]) {
        void this.subscribeToEntity(entry.entityId);
      }
    });
  };

  onOverviewsChange = (overviews: readonly ListOverview[] | null) => {
    if (!overviews) {
      this.totalListOverviews.set(0);
    }
  };

  private reportError(e: unknown) {
    const msg = prettyError(e);
    toast.error("Error loading list: " + msg);
    errorTracker.sendError(e, { source: "lists-store" });
  }

  private requireList() {
    const list = this.list.get();
    if (!list) {
      logger.error("No list found");
      throw new Error("No list found");
    }
    return list;
  }

  private requireView() {
    const view = this.view.get();
    if (!view) {
      logger.error("No view found");
      throw new Error("No view found");
    }
    return view;
  }

  loadOverviews = async (paging: PagingOptions = { page: 0, pageSize: 1000 }) => {
    try {
      const { result, total } = await API.lists.list(paging);
      this.listOverviews.set(result);
      this.totalListOverviews.set(total);
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  loadList = async (id: string) => {
    try {
      this.list.set(null);
      const list = await API.lists.get(id);
      this.list.set(list);
    } catch (e: unknown) {
      this.list.set(null);
      this.reportError(e);
    }
  };

  loadEntries = async (listId: string, paging: PagingOptions) => {
    try {
      if (!paging.pageSize) paging.pageSize = this.pageSize.get();
      this.page.set(paging.page);
      const { result: entries, total } = await API.listEntries.list(listId, paging);
      this.totalEntries.set(total);
      this.entries.set(entries);
    } catch (e: unknown) {
      this.entries.set(null);
      this.reportError(e);
    }
  };

  loadSnapshots = async (options: {
    paging: PagingOptions;
  }): Promise<ListEntrySnapshotsResult | null> => {
    const { paging } = options;
    const view = this.requireView();
    try {
      const result = await API.listViews.snapshots(view.id, {
        ...paging,
        listId: view.list.id,
      });
      const snapshots = paging.page === 0 ? [] : this.snapshots.get() || [];
      this.snapshots.set([...snapshots, ...result.result]);
      this.totalSnapshots.set(result.total);
      return result;
    } catch (e: unknown) {
      this.reportError(e);
      return null;
    }
  };

  createList = async (data: CreateListData) => {
    try {
      const newList = await API.lists.create(data);
      this.list.set(newList);
      return newList;
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  updateList = async (data: UpdateListData) => {
    try {
      const updatedList = await API.lists.update(data.listId, data);
      this.list.set(updatedList);
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  deleteList = async (id: string) => {
    const currentOverviews = this.listOverviews.get() || [];
    try {
      // Optimistically remove the list from the list of overviews
      this.listOverviews.set(currentOverviews.filter((overview) => overview.id !== id));
      await API.lists.delete(id);
      this.list.set(null);
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  private validateEntries(entrySpecs: readonly ListEntrySpec[]) {
    const currentSize = this.totalEntries.get();
    const maxSize = this.maxSize.get();
    const newSize = currentSize + entrySpecs.length;
    if (newSize > maxSize) {
      const listId = this.requireList().id;
      throw new ListMaxSizeReachedError({
        listId,
        currentSize,
        newSize,
        maxSize,
      });
    }
  }

  addEntries = async (
    entrySpecs: ListEntrySpec[],
    options?: { listId?: string },
  ): Promise<ListEntryDetails[] | null> => {
    this.validateEntries(entrySpecs);
    try {
      const listId = options?.listId || this.requireList().id;
      const newEntries = await API.listEntries.create(listId, { entrySpecs });
      const totalEntries = this.totalEntries.get();
      this.entries.set(
        [...(this.entries.get() || []), ...newEntries].sort((a, b) => a.order - b.order),
      );
      this.totalEntries.set(totalEntries + newEntries.length);
      return newEntries;
    } catch (e: unknown) {
      this.reportError(e);
      return null;
    }
  };

  updateEntries = async (
    updates: ListEntryUpdate[],
    options?: { listId?: string; pessimistic?: boolean },
  ): Promise<void> => {
    try {
      const listId = options?.listId || this.requireList().id;
      const pessimistic = options?.pessimistic || false;
      if (pessimistic) {
        const updatedEntries = await API.listEntries.update(listId, { entries: updates });
        const updatedEntriesMap = new Map(updatedEntries.map((entry) => [entry.id, entry]));
        const mergedEntries = (this.entries.get() || []).map(
          (entry) => updatedEntriesMap.get(entry.id) || entry,
        );
        this.entries.set(mergedEntries);
      } else {
        await this.optimisticUpdateEntries(updates, listId);
      }
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  private async optimisticUpdateEntries(updates: ListEntryUpdate[], listId: string) {
    const updatedEntries = (this.entries.get() || []).map((entry) => {
      const update = updates.find((e) => e.entryId === entry.id);
      if (update) {
        return this.mergeEntry(entry, update);
      }
      return entry;
    });
    this.entries.set(updatedEntries);
    await API.listEntries.update(listId, { entries: updates });
  }

  private mergeEntry(existing: ListEntryDetails, update: ListEntryUpdate): ListEntryDetails {
    const { data: existingData, customFields: existingCustomFields } = existing;
    const { data: updatedData, customFields: updatedCustomFields, ...updateRest } = update;
    const result = { ...existing, ...updateRest };
    if (updatedData) {
      result.data = { ...existingData, ...updatedData };
    }
    if (updatedCustomFields) {
      result.customFields = this.mergeCustomFields(existingCustomFields, updatedCustomFields);
    }
    return result;
  }

  private mergeCustomFields(existing: ListCustomFieldValue[], updates: ListEntryCustomFieldSpec[]) {
    const newFields = updates
      .filter((update) => !existing.some((field) => field.fieldId === update.fieldId))
      .map((update) => update as ListCustomFieldValue);

    const mergedFields = existing.reduce<ListCustomFieldValue[]>((acc, field) => {
      const updateField = updates.find((f) => f.fieldId === field.fieldId);
      if (!updateField || updateField.data === "delete") {
        return [...acc, field];
      }
      return [...acc, { ...field, data: updateField.data }];
    }, []);

    return [...mergedFields, ...newFields];
  }

  deleteEntries = async (entryIds: string[]) => {
    try {
      const list = this.requireList();
      await API.listEntries.delete(list.id, entryIds);
      const totalEntries = this.totalEntries.get();
      const entries = this.entries.get() || [];
      const prevEntryCount = entries.length;
      const remainingEntries = entries.filter((entry) => !entryIds.includes(entry.id));
      this.entries.set(remainingEntries);
      this.totalEntries.set(totalEntries - (prevEntryCount - remainingEntries.length));
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  createView = async (options?: { publish?: boolean }) => {
    try {
      const { publish } = options || {};
      const list = this.requireList();
      const existingView = this.view.get();
      if (existingView) {
        logger.error("View already exists");
        throw new Error("View already exists");
      }
      const view = await API.listViews.create(list, {
        displayMode: "cards",
        ...(publish && { publishedAt: new Date() }),
      });
      this.view.set(view);
      const publicUrl = ListRoutes.view(view, { listName: list.name, prefix: location.origin });
      await navigator.clipboard.writeText(publicUrl);
      toast.success("Link copied to clipboard!");
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  updateView = async (data: UpdateViewParams) => {
    try {
      const list = this.requireList();
      const existingView = this.requireView();

      const optimisticView = { ...existingView, ...data };
      this.view.set(optimisticView);

      await API.listViews.update(list.id, existingView.id, data);
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  private subscribeToEntity = async (entityId: string) => {
    if (!uiStore.user.get()) return;
    const realtime = await uiStore.getConnectedRealtime();
    const channel = realtime.channels.get("entity:" + entityId);

    if (!channel) {
      logger.debug("no channel for entity", entityId);
      return;
    }
    logger.debug("subscribing to entity", entityId);
    this.subscriptions[entityId] = channel;

    channel.subscribe("progress", (msg) => {
      const progress = msg.data as EntityProgressUpdate;
      logger.debug("entity progress", entityId, progress);
      this.entitiesInProgress.setKey(entityId, progress);
    });

    channel.subscribe("finished", (msg) => {
      logger.debug("entity finished", entityId, msg);
      this.entitiesInProgress.setKey(entityId, undefined);
      const channel = this.subscriptions[entityId];
      if (channel) {
        try {
          channel.unsubscribe();
        } catch (e) {
          logger.error("error unsubscribing from entity", entityId, e);
        }
        Reflect.deleteProperty(this.subscriptions, entityId);
      }
      const entries = this.entries.get() || [];
      const updatedEntries = entries.map((entry) => {
        if (entry.entityId === entityId) {
          return { ...entry, entity: { ...entry.entity, generatedAt: new Date() } };
        }
        return entry;
      });
      this.entries.set(updatedEntries as ListEntryDetails[]);
    });
  };
}

export const listStore = new ListStore();

listStore.list.listen((listDetails) => {
  listStore.onListChange(listDetails);
});

listStore.entries.listen((entries) => {
  listStore.onEntriesChange(entries);
});

listStore.listOverviews.listen((overviews) => {
  listStore.onOverviewsChange(overviews);
});

listStore.view.listen((view) => {
  listStore.onViewChange(view);
});

listStore.snapshots.listen((snapshots) => {
  listStore.onSnapshotsChange(snapshots);
});

listStore.entriesWithQueuedEntities.listen((entries) => {
  listStore.onEntriesWithQueuedEntitiesChange(entries);
});

export default listStore;
